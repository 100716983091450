import { useEffect, useState } from 'react';
import { message } from 'antd';

import ToppingType from '@/types/ToppingType';
import IMixTopping from '@/interfaces/IMixTopping';
import IMixSection from '@/interfaces/IMixSection';
import IMixBaseProduct from '@/interfaces/IMixBaseProduct';

import useLocation from '@/hooks/useLocation';

import { getMixBaseProducts } from '@/services/MixService';
import IMix from '@/interfaces/IMix';
import getInputType from '@/helpers/getInputType';
import getToppingPrice from '@/helpers/getToppingPrice';
import { useTranslation } from 'react-i18next';
import IWarehouse from '@/interfaces/IWarehouse';
import ItemType from '@/types/ItemType';
import precision from '@/helpers/precision';
import { getProductsRelatedToItem } from '@/services/RelatedProduct';
import IProductRelatedToList from '@/interfaces/IProductRelatedToList';
import EVisibility from '@/enums/EVisibility';
import IMixRestrictions from '@/interfaces/IMixRestrictions';
import Optional from '@/types/Optional';

export type SelectedToppingsType = ( 
  IMixTopping & {
    qtySelected?: number;
    sectionId: IMixSection['id'];
  }
);

export interface SelectedValueType {
  qty?: number;
  checked?: boolean;
}

interface IMixConfigurationProps {
  mix: IMix;
}

interface IMixConfgigurationRes {
  allowAddToCard: boolean,
  selectedToppings: SelectedToppingsType[];
  mixBaseProducts: IMixBaseProduct[];
  mixSection: IMixSection[];
  loading: boolean;
  price: number;
  relatedItems: IProductRelatedToList[];
  mixRestrictions: IMixRestrictions;
  mixHasInventory: boolean;
  getMixRestrictions: (mix: IMix, warehouseId?: IWarehouse['id']) => Promise<void>;
  initToppings: () => void;
  onChangeToppings: (
    topping: IMixTopping, 
    sectionId: IMixSection['id'], 
    type: ToppingType, 
    value: SelectedValueType
  ) => void;
  initConfiguration: () => Promise<void>;
  getMixPrice: () => void;
  getRelatedProducts: (
    productId: IMix['id'], 
    warehouseId: Optional<IWarehouse['id']>, 
    entityType: ItemType,
  ) => Promise<void>
}

const useProductConfgiguration = ({
  mix,
}: IMixConfigurationProps): IMixConfgigurationRes => {
  const [selectedToppings, setSelectedToppings] = useState<SelectedToppingsType[]>([]);
  const [relatedItems, setRelatedItems] = useState<IProductRelatedToList[]>([]);
  const [mixBaseProducts, setMixBaseProducts] = useState<IMixBaseProduct[]>([]);
  const [mixHasInventory, setMixHasInventory] = useState<boolean>(false);
  const [mixSection, setMixSection] = useState<IMixSection[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);

  const [mixRestrictions, setMixRestrictions] = useState<IMixRestrictions>({
    visibility: EVisibility.Always,
  });

  const [allowAddToCard, setAllowAddToCard] = useState<boolean>(false);
  const sectionsRestrictionsRequired = mixSection.filter(
    section => section.restrictions.limit === 'req' && section.toppings.length > 0
  ).map(
    section => ({min: section.restrictions.min, sectionId: section.id})
  );

  const { selectedWarehouse } = useLocation();
  const { t } = useTranslation('common');

  function checkIsAllowAddToCard(){
    let canAddToCart = true;

    sectionsRestrictionsRequired.forEach(sectionRestriction => {
      const selectedSectionToppings = selectedToppings
        .filter(selectedTopping => (
          selectedTopping.sectionId === sectionRestriction.sectionId
          && selectedTopping.qtySelected 
          && selectedTopping.qtySelected > 0
        ));

      // calc total amount of toppings selected per section
      const toppingsTotalSelections = selectedSectionToppings.reduce((acc, section) => acc + (section.qtySelected ?? 0), 0);
      if(toppingsTotalSelections < sectionRestriction.min){
        canAddToCart = false;
        return;
      }
    });

    setAllowAddToCard(canAddToCart);
  }

  async function checkMixHasInventory(): Promise<void> {
    try {
      setLoading(true);
      let hasInventory = true;

      for (let index = 0; index < mixBaseProducts.length; index++) {
        const { product, qty } = mixBaseProducts[index];

        const productInventory = await product.getInventory({
          selectionItem: product.id, 
          warehouseId: selectedWarehouse
        });
        
        const productsConfigData = await product.getProductsConfig({
          warehouseId: selectedWarehouse,
        });
        
        if(productInventory < qty && !productsConfigData[0]?.inventory.allowSaleWithoutInventory){
          hasInventory = false;
          break;
        }
      }
      
      setMixHasInventory(hasInventory);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }

  const initToppings = () => {
    const newToppings: SelectedToppingsType[] = [];
    
    mixSection.forEach(section => {
      const inputType = getInputType(
        section.restrictions,
        section.toppings
      );

      if (inputType === 'qty') {
        const selectedToppinsType: SelectedToppingsType[] = section.toppings.map(topping => {
          return {
            ...topping,
            qtySelected: 0,
            sectionId: section.id,
          }
        });

        newToppings.push(
          ...selectedToppinsType
        );
      }
    });

    setSelectedToppings(newToppings);
  };

  const onChangeToppings = (
    topping: IMixTopping, 
    sectionId: IMixSection['id'],
    type: ToppingType,
    value: SelectedValueType,
  ) => {
    const sectionConfig = mixSection.find(mSection => mSection.id === sectionId);
    if(!sectionConfig) return; 

    if (value.checked) {
      const currentToppingsInSection = selectedToppings.filter(sTopping => sTopping.sectionId === sectionId);
  
      if ((currentToppingsInSection.length + 1) > sectionConfig.restrictions.max && type !== 'radio') {
        message.warning({ content: t('g.amount_exceeded'), duration: 2, key: 'amountExceededKey' });
        return;
      }
    }

    if (type === "qty") {
      const toppingConfig = sectionConfig.toppings.find(sTopping => sTopping.id === topping.id);
      // console.log({ toppingConfig });
      if (!toppingConfig) return;

      if (value.qty) {
        const stepper = toppingConfig.restrictions.multiplier !== 0 ? toppingConfig.restrictions.multiplier : 1;
        const selections = ((value.qty - toppingConfig.restrictions.min) / stepper) + 1;
  
        if (selections > toppingConfig.restrictions.max) {
          message.warning({ content: t('g.amount_exceeded'), duration: 2, key: 'amountExceededKey' });
          return;
        }
      }

      const isSelectedTopping = selectedToppings.map(
        (selectedTopping: SelectedToppingsType) => {
          if (
            selectedTopping.id === topping.id
            && selectedTopping.sectionId === sectionId
          ) {
            const { decimalPoints } = selectedTopping.product;

            const qtySelected = +(value.qty ?? 0).toFixed(decimalPoints);
            
            return {
              ...topping,
              qtySelected,
              sectionId: sectionId,
            };
          }

          return selectedTopping;
        }
      );

      const qtyToppingsInSection = isSelectedTopping.reduce((acc: number, crr: SelectedToppingsType) => {
        if (crr.sectionId !== sectionId) return acc;

        const qty = crr.qtySelected ?? 0;
        const { min }= crr.restrictions;
        const { multiplier }= crr.restrictions;
        const selections = qty === 0 ? 0 : Math.round(((qty - min) / multiplier) + 1);
        // console.log({ 
        //   qty,
        //   min,
        //   multiplier, 
        //   selections,
        // });

        return acc + selections;
      }, 0);

      if (qtyToppingsInSection > sectionConfig.restrictions.max) {
        message.warning({ content: t('g.amount_exceeded'), duration: 2, key: 'amountExceededKey' });
        return;
      }

      setSelectedToppings(isSelectedTopping);
    }

    if (type === 'checkbox') {
      if (value.checked) {
        const newToppings = [...selectedToppings, {
          ...topping,
          qtySelected: topping.restrictions.min,
          sectionId: sectionId,
        }];

        setSelectedToppings(newToppings);
        return;
      }

      deleteTopping(topping.id, sectionId);
    }

    if (type === 'radio') {
      if (value.checked) {
        const toppings = deleteToppings(sectionId);
        
        const newToppings = [...toppings, {
          ...topping,
          qtySelected: topping.restrictions.min,
          sectionId: sectionId,
        }];

        setSelectedToppings(newToppings);
        return;
      }

      const toppings = deleteToppings(sectionId);
      setSelectedToppings(toppings);
    }
  };

  const initConfiguration = async () => {
    setLoading(true);

    const mixesSection: IMixSection[] = await Promise.all(
      mix.sections.map(async section => {
        const newToppins = await Promise.all(
          section.toppings.map(
            async topping => ({
              ...topping,
              isAvailable: await getIsToppingAvailable(topping), 
              priceRestrictions: {
                ...topping.priceRestrictions,
                price: await getToppingPrice(topping, selectedWarehouse ?? ''),
              }
            })
          ),
        );

        return {
          ...section,
          toppings: newToppins,
        };
      }),
    );

    const baseProducts = await getMixBaseProducts({
      mixId: mix.id,
    });

    setMixBaseProducts(baseProducts);
    setMixSection(mixesSection);
    setLoading(false);
  };
  
  const getMixRestrictions = async (mixObj: IMix, warehouseId?: IWarehouse['id']) => {
    setLoading(true);

    const mixRestrictions = await mix.getRestrictions({ mix: mixObj, warehouseId });
    setMixRestrictions(mixRestrictions);

    setLoading(false);
  };

  const getMixPrice = () => {
    const filterToppings = selectedToppings.filter(selectedTopping => (
      selectedTopping.qtySelected !== 0
    ));
    const price = filterToppings.reduce((acc, curr) => {
      if (curr.qtySelected) return (curr.qtySelected * curr.priceRestrictions.price) + acc;

      return curr.priceRestrictions.price + acc;
      
    }, 0);

    setPrice(price + mix.price);
  };

  const deleteTopping = (
    toppingId: IMixTopping['id'],
    sectionId: IMixSection['id'],
  ) => {
    const filterToppings = selectedToppings.filter(selectedTopping => {
      if (
        selectedTopping.id === toppingId
        && selectedTopping.sectionId === sectionId
      ) return false;

      return true;
    });

    setSelectedToppings(filterToppings);
  };

  const deleteToppings = (
    sectionId: IMixSection['id'],
  ) => {
    const filterToppings = selectedToppings.filter(selectedTopping => {
      if (selectedTopping.sectionId === sectionId) return false;

      return true;
    });

    return filterToppings;
  };

  const getIsToppingAvailable = async (topping: IMixTopping): Promise<boolean> => {
    if (!selectedWarehouse) return true;

    const productConfig = await topping.product.getProductsConfig({ warehouseId: selectedWarehouse });

    if (productConfig[0] && productConfig[0]?.inventory?.allowSaleWithoutInventory) return true;
    
    const inventory = await topping.product.getInventory({warehouseId: selectedWarehouse});

    return inventory > 0;
  };

  const getRelatedProducts = async (
    productId: IMix['id'],
    warehouseId: Optional<IWarehouse['id']>,
    entityType: ItemType,
  ) => {
    try {
      setLoading(true);
      const relatedProducts = await getProductsRelatedToItem({
        warehouseId,
        skip: 0,
        limit: 10,
        productsData: [ { id: productId, entityType, }],
      });
      // console.log('related items ...', relatedProducts);
      setRelatedItems(relatedProducts.data);
    } catch (error) {
      console.error('related items ...', error);
      setRelatedItems([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mix, selectedWarehouse]);

  useEffect(() => {
    checkMixHasInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixBaseProducts]);

  useEffect(() => {
    initToppings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixSection]);

  useEffect(() => {
    getMixPrice();
    checkIsAllowAddToCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToppings]);

  return {
    allowAddToCard,
    selectedToppings,
    mixBaseProducts,
    mixSection,
    loading,
    price,
    relatedItems,
    mixRestrictions,
    mixHasInventory,
    getMixRestrictions,
    initToppings,
    getRelatedProducts,
    onChangeToppings,
    initConfiguration,
    getMixPrice,
  };
};

export default useProductConfgiguration;
