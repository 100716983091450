import { Button, Col, List, Row, Space, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import EDeliveryMethod from '@/enums/EDeliveryMethod';
import EPriceFormat from '@/enums/EPriceFormat';
import allowsSaleWithoutInventory from '@/helpers/allowsSaleWithoutInventory';
import getAttributesFromSelectedProduct from '@/helpers/getAttributesFromSelectedProduct';
import getTranlation from '@/helpers/getTranslation';
import isMix from '@/helpers/isMix';
import isVariant from '@/helpers/isVariant';
import useLocation from '@/hooks/useLocation';
import IShoppingCartItemWithConfig from '@/interfaces/IShoppingCartItemWithConfig';
import CustomBadge from '@/components/General/CustomBadge';
import Price from '@/components/General/Price';
import QtyInput from '@/components/General/QtyInput';
import ItemCard from '@/components/ShoppingCart/ItemCard';
import ItemDetails from '@/components/ShoppingCart/ItemDetails';
import ItemAvailability from '../Item/ItemAvailability';
import EInventoryStatus from '@/enums/EInventoryStatus';
import getEInventoryStatus from '@/helpers/getEInventoryStatus';
import getShoppingCartItemStepper from '@/helpers/getShoppingCartItemStepper';
import getMixToppingSelection from '@/helpers/getMixToppingSelection';
import truncateText from '@/helpers/truncateText';
import useBreakpoints from '@/hooks/useBreakpoint';
import { NotFoundImage } from '@/assets/images';
import precision from '@/helpers/precision';
import isProduct from '@/helpers/isProduct';

interface ShoppingCartItemsListProps {
  shoppingCartItems: IShoppingCartItemWithConfig[];
  hideBadge?: boolean;
  onIncrease?: (i: IShoppingCartItemWithConfig) => Promise<void>;
  onDecrease?: (i: IShoppingCartItemWithConfig) => Promise<void>;
  onDelete?: (i: IShoppingCartItemWithConfig) => Promise<void>;
}

const ShoppingCartItemsList = ({
  shoppingCartItems,
  onDecrease,
  onIncrease,
  onDelete,
  hideBadge = false,
}: ShoppingCartItemsListProps) => {
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  const truncateSize = isBreakpointEqualsAndBelow('sm') ? 20 : 40;
  const { t } = useTranslation('common');
  const location = useLocation();

  const Actions = (shoppingCartItem: IShoppingCartItemWithConfig, index: number) => {
    if (!shoppingCartItem.item) return;
    console.log({ qty: shoppingCartItem.qty });
    const precision = isProduct(shoppingCartItem.item) ? shoppingCartItem.item.decimalPoints : 0;

    if (onIncrease && onDecrease && onDelete) {
      return (
        <Row gutter={16} align="middle">
          <Col>
            {showInput(shoppingCartItem) ? (
              <QtyInput
                disableIncrease={disableAddButton(shoppingCartItem)}
                value={+(shoppingCartItem.qty).toFixed(precision)}
                onIncrease={() => onIncrease(shoppingCartItem)}
                onDecrease={() => onDecrease(shoppingCartItem)}
                min={getMinQtyAllowed(shoppingCartItem)}
                max={getMaxQtyAllowed(shoppingCartItem)}
              />
            ) : undefined}
          </Col>
          <Col>
            <Price
              stylePrice={{ fontSize: "16px", fontWeight: "bold" }}
              loading={!shoppingCartItem.price && shoppingCartItem.isInWarehouse}
              comparativePrice={getComprativeValue(shoppingCartItem)}
              price={(shoppingCartItem.price ?? 0) * shoppingCartItem.qty}
              priceFormat={getPriceFormat(shoppingCartItem)}
            />
          </Col>
          <Col>
            <Button onClick={() => onDelete(shoppingCartItem)}>{t('g.delete')}</Button>
          </Col>
        </Row>
      );
    }
    return undefined;
  };

  const showInput = (item: IShoppingCartItemWithConfig) => {
    if (!item.isInWarehouse) return false;
    if (item.price === 0 || !item.price) return false;
    
    return true;
  };

  const disableAddButton = (item: IShoppingCartItemWithConfig) => {
    const hasEnoughInventory = item.qty <= item.inventory;
    const allowsSale = allowsSaleWithoutInventory(item.config);

    return !hasEnoughInventory && !allowsSale;
  };

  const getMinQtyAllowed = (item: IShoppingCartItemWithConfig) => {
    const productStepper = getShoppingCartItemStepper(item);

    return productStepper;
  }

  const getMaxQtyAllowed = (item: IShoppingCartItemWithConfig) => {
    const allowsSale = allowsSaleWithoutInventory(item.config);
    if (allowsSale) return undefined;

    const productStepper = getShoppingCartItemStepper(item);
    const stepperResidue = item.inventory % productStepper;

    let maxPurchaseSelectionQty = item.inventory;

    if(stepperResidue !== item.inventory && stepperResidue > 0){
      maxPurchaseSelectionQty = item.inventory - stepperResidue;
    }

    return maxPurchaseSelectionQty;
  };

  const getComprativeValue = (shoppingCartItem: IShoppingCartItemWithConfig) => {
    if (!shoppingCartItem?.item) return undefined;
    if (isMix(shoppingCartItem.item)) return undefined;
    const comprativeValue = shoppingCartItem.config[0]?.formats?.comparativeValue ?? 0;

    return comprativeValue * shoppingCartItem.qty;
  };

  const getPriceFormat = (shoppingCartItem: IShoppingCartItemWithConfig) => {
    if (!shoppingCartItem?.item) return EPriceFormat.Regular;
    if (isMix(shoppingCartItem.item)) return EPriceFormat.Regular;

    return shoppingCartItem?.config[0]?.formats.price ?? EPriceFormat.Regular;
  };

  const ImagePreviewContainer = (props: { shoppingCartItem: IShoppingCartItemWithConfig }) => {
    const { shoppingCartItem } = props;
    
    const itemImgSrc = shoppingCartItem?.item?.imgs[0]?.url || NotFoundImage;

    return (
      <LazyLoadImage
        alt={shoppingCartItem?.item?.name?.es}
        placeholderSrc={NotFoundImage}
        src={itemImgSrc}
        // className="h-56
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          aspectRatio: 1,
          marginBottom: '8px',
          backgroundColor: '#f3f3f3',
        }}
      />
    )
  };

  const ToppingTags = (props: { shoppingCartItem: IShoppingCartItemWithConfig }) => {
    const toppings = getMixToppingSelection(props.shoppingCartItem);
    
    return (
      <Row gutter={[8, 8]}>
        {
          toppings.map(topping => (
            <Col key={`extra-${topping.sectionId}-${topping.product.id}`}>
              <Tag style={{ margin: 0 }}>
                {`[${topping.qty} ${topping.product.unit.code}] ${truncateText(getTranlation(topping.product.name), truncateSize)}`}
              </Tag>
            </Col>
          ))
        }
      </Row>
    );
  };

  const getDeliveryMethods = (hasShippingType: boolean, hasDeliveryType: boolean) => {
    if (!hasShippingType && !hasDeliveryType) return [];
    if (!hasShippingType && hasDeliveryType) return [EDeliveryMethod.Pickup]
    if (hasShippingType && !hasDeliveryType) return [EDeliveryMethod.Shipping]

    return [EDeliveryMethod.Pickup, EDeliveryMethod.Shipping];
  };

  return (
    <List
      grid={{ gutter: 16, column: 1 }}
      dataSource={shoppingCartItems}
      renderItem={(shoppingCartItem: IShoppingCartItemWithConfig, index: number) => {
        return (
          <List.Item>
            <ItemCard
              layout='horizontal'
              thumRatio={1.5}
              thumb={
                <CustomBadge color="blue" count={shoppingCartItem.qty} hidden={hideBadge} >
                  <ImagePreviewContainer shoppingCartItem={shoppingCartItem} />
                </CustomBadge>
              }
              title={
                shoppingCartItem.item && (
                  <ItemDetails
                    deliveryMethods={getDeliveryMethods(
                      shoppingCartItem.shippingType, 
                      shoppingCartItem.deliveryPoint,
                    )}
                    priceSection={
                      <Space>
                        <Typography.Text>
                          Precio unitario
                        </Typography.Text>
                        <Price
                          loading={!shoppingCartItem.price && shoppingCartItem.isInWarehouse}
                          comparativePrice={getComprativeValue(shoppingCartItem)}
                          price={shoppingCartItem.price ?? 0}
                          priceFormat={getPriceFormat(shoppingCartItem)}
                        />
                      </Space>
                    }
                    item={shoppingCartItem.item}
                  />
                )
              }
              content={
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    {
                      location.selectedWarehouse ? (
                        <ItemAvailability
                          withInventory={shoppingCartItem.isInWarehouse ? getEInventoryStatus(shoppingCartItem.inventory, shoppingCartItem.qty) : EInventoryStatus.WithoutInventory}
                          allowSaleWithoutInventory={shoppingCartItem.isInWarehouse ? shoppingCartItem.config.every(item => item.inventory.allowSaleWithoutInventory): false}
                          currentQty={shoppingCartItem.inventory}
                          format={shoppingCartItem.config.length === 1 ? shoppingCartItem.config[0].formats.soldOut : undefined}
                        />
                      ) : (
                        <ItemAvailability
                          withInventory={EInventoryStatus.WithoutInventory}
                        />
                      )
                    }
                  </Col>
                  {shoppingCartItem.item && isVariant(shoppingCartItem.item) && 
                    <Col span={24}>
                      {getAttributesFromSelectedProduct(
                        shoppingCartItem.item,
                        shoppingCartItem.selection
                      ).map((attOp) => (
                        <Tag key={`attr-${attOp.id}`}>{getTranlation(attOp.label)}</Tag>
                      ))}
                    </Col>
                  }
                  {
                    (shoppingCartItem.item && isMix(shoppingCartItem.item) && shoppingCartItem.baseProducts) && (
                      <Col span={24}>
                        <Typography.Title level={5}>
                          Productos base
                        </Typography.Title>
                        <Row gutter={[8, 8]}>
                          {
                            shoppingCartItem.baseProducts.map((baseProduct) => (
                              <Col key={`base-${baseProduct.product.id}`}>
                                <Tag style={{ margin: 0 }}>
                                  {`[${baseProduct.qty} ${baseProduct.product.unit.code}] ${truncateText(getTranlation(baseProduct.product.name), truncateSize)}`}
                                </Tag>
                              </Col>
                            ))
                          }
                        </Row>
                      </Col>
                    )
                  }

                  {shoppingCartItem.item && isMix(shoppingCartItem.item) && 
                    <Col span={24}>
                      <Typography.Title level={5}>
                        Extras
                      </Typography.Title>
                      <ToppingTags shoppingCartItem={shoppingCartItem} />
                    </Col>
                  }
                </Row>
              }
              actions={Actions(shoppingCartItem, index)}
            />
          </List.Item>
        );
      }}
    />
  );
};

export default ShoppingCartItemsList;
